import { ReactElement } from "react";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { useAppSelector } from "../store/hooks";
import { selectSurveyTitle } from "../store/surveySlice";

import config from "../lib/config";

const Header = (): ReactElement => {
	const surveyTitle = useAppSelector(selectSurveyTitle);

	return (
		<header>
			<Navbar bg="info" variant="dark" className="d-flex justify-content-between px-4 px-md-5">
				<Navbar.Brand className="p-0 ms-sm-2 mx-md-0 justify-content-start logo-about">
					<img alt={config.brandIcon.alt} src={config.brandIcon.src.desktop} height="31" className="align-center" />
				</Navbar.Brand>
				<Navbar.Text className="d-none d-lg-flex text-center pb-1 pt-2 mx-auto">
					<h1 className="h6 m-0 text-white">
						<span className="fw-header">{surveyTitle ? surveyTitle : config.title}</span>
					</h1>
				</Navbar.Text>
				<Nav>
					<a href="https://abley.com/carbonwise/" target="_blank" className="logo-about text-end text-decoration-none fw-bold" title="About">
						About
					</a>
				</Nav>
			</Navbar>
		</header>
	);
};

export default Header;
